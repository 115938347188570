









































import { Component, Vue } from "vue-property-decorator";
import TimelinePoint from "@5amcode/sunrise-components/src/TimelinePoint";

@Component
export default class PreviewTimeline extends Vue {
  private timelineArrangement = "left";

  sampleEvents = [
    new TimelinePoint(
      "Big Event",
      "Nov 11, 2020",
      `Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam vel repellat ipsum aliquid laboriosam. Laudantium sunt maxime voluptate perspiciatis necessitatibus sequi voluptates, quos blanditiis unde suscipit, explicabo cum harum neque?`
    ),
    new TimelinePoint(
      "Tailwind 2.0 Release",
      "Nov 18, 2020",
      `Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam vel repellat ipsum aliquid laboriosam.`
    ),
    new TimelinePoint("Big Event 5", "Nov 25, 2020", `Lorem ipsum`),
  ];

  switchTimeline() {
    if (this.timelineArrangement == "left") this.timelineArrangement = "right";
    else this.timelineArrangement = "left";
  }
}
